<template>
<div>
  <div v-if="fileUrl != '' && pageNum > 0">
    <pdf v-for="idx in pageNum" :key="idx" :src="fileUrl" :page="idx"></pdf>
  </div>
</div>
</template>
<script>
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { documentInfo } from '@/api/quotation'
import Vue from 'vue'
import pdf from 'vue-pdf';

export default {
  data(){
    return {
      fileUrl: '',
      pageNum: 0,
    };
  },
  components: {
    pdf,
  },
  mounted () {
    let parameter = {"type": 201};
    documentInfo(parameter)
      .then(res => {
        if (0 == res.code) {
          this.fileLoad("/service_provider/upload/getPic?fileName=" + res.result.document);
        } else {
          this.$message.error('数据数据失败');
        }
      }).catch(e => {
        console.info(e);
        this.$message.error('获取数据失败，请稍后重试');
      });
  },
  methods: {
    fileLoad(url) {
      let that = this;
      let token = Vue.ls.get(ACCESS_TOKEN)
      let request = new XMLHttpRequest()
      request.timeout = 9000
      request.responseType = 'blob'
      request.open('get', url, true)
      request.setRequestHeader('token', token)
      request.onreadystatechange = (e) => {
        if (request.readyState === XMLHttpRequest.DONE && request.status === 200) {
          that.fileUrl = URL.createObjectURL(request.response);
          that.pdfPreview();
        }
      }
      request.send(null)
    },
    pdfPreview(){
      let that = this;
      let loadingTask = pdf.createLoadingTask(this.fileUrl);
      loadingTask.promise.then(pdf => {
        that.pageNum = pdf.numPages;
      }).catch(e => {
        console.info(e);
        that.$message.error('文件加载失败，请稍后重试');
      });
    },
  },
};

</script>
